<template>
  <div id="UploadStoreProduct">
    <v-container>
      <v-row>
        <v-col class="col-16">
          <v-card>
            <v-app-bar dense color="transparent">
              <v-toolbar-title>
                <h5>Upload Produk Toko</h5>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="dialog = true"
                >Upload Banyak</v-btn
              >
            </v-app-bar>

            <v-card-text>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-row>
                  <v-col class="col-2">Nama Produk</v-col>
                  <v-col class="col-5">
                    <v-text-field
                      dense
                      v-model="mitraProduct.prodName"
                      placeholder="Masukkan Nama Produk"
                      :rules="prodNameRules"
                      outlined
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="col-2">Kode Produk</v-col>
                  <v-col class="col-5">
                    <v-text-field
                      dense
                      v-model="mitraProduct.prodCode"
                      placeholder="Masukkan Kode Produk"
                      :rules="prodCodeRules"
                      outlined
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="col-2">Unit</v-col>
                  <v-col class="col-3">
                    <v-select
                      dense
                      outlined
                      v-model="mitraProduct.unit"
                      :items="unitData"
                      item-value="unit_code"
                      menu-props="auto"
                      label="Pilih Satuan"
                      :rules="prodUnitRules"
                      clearable
                    >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.unit_code }} - {{ data.item.unit_name }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.unit_code }} - {{ data.item.unit_name }}
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="col-2">Kategori</v-col>
                  <v-col class="col-3">
                    <v-select
                      dense
                      outlined
                      v-model="mitraProduct.category"
                      :items="categoryData"
                      item-text="category_name"
                      item-value="category_name"
                      menu-props="auto"
                      label="Pilih Kategori"
                      :rules="prodCategoryRules"
                      clearable
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="col-2">HPP</v-col>
                  <v-col class="col-5">
                    <vuetify-money
                      dense
                      v-model="mitraProduct.cost"
                      placeholder="Masukkan Harga Modal"
                      v-bind:options="options"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="col-2">Harga Jual</v-col>
                  <v-col class="col-5">
                    <vuetify-money
                      dense
                      v-model="mitraProduct.sellingPrice"
                      placeholder="Masukkan Harga Jual"
                      v-bind:options="options"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>

              <v-row>
                <v-col class="col-7 d-flex justify-end">
                  <v-btn
                    color="success"
                    @click="submitStoreProduct"
                    :loading="loading"
                    :disabled="loading"
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" max-width="1200">
      <v-card class="mx-auto" id="StoreProductTable" outlined>
        <v-app-bar dense color="transparent">
          <v-toolbar-title>
            <h5>Upload Banyak Produk</h5>
          </v-toolbar-title>
        </v-app-bar>
        <v-card-text>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-file-input
              show-size
              label="Masukkan File Excel*"
              accept=".xlsx, .xls"
              v-model="file"
              :rules="fileRules"
              @keydown.enter="submitData"
            ></v-file-input>

            <div v-if="isFile">
              <v-responsive
                class="overflow-auto"
                max-height="calc(90vh - 150px)"
              >
                <xlsx-read :file="file">
                  <xlsx-table />
                </xlsx-read>
              </v-responsive>
            </div>

            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  color="success"
                  @click="submitData"
                  :loading="loadingStatus"
                  :disabled="loadingStatus"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { XlsxRead, XlsxTable } from "vue-xlsx/dist/vue-xlsx.es.js";
import { ERROR_FILE_FORMAT } from "@/utils/message";
import api from "@/services/ApiService";

export default {
  components: {
    XlsxRead,
    XlsxTable
  },
  props: { shown: { type: Boolean, default: false } },

  data: () => ({
    file: null,
    valid: true,
    dialog: false,
    loading: false,
    mitraProduct: {},
    categoryData: [],
    unitData: [],
    fileRules: [v => !!v || "File wajib diisi"],
    prodNameRules: [v => !!v || "Nama Produk wajib diisi"],
    prodCodeRules: [v => !!v || "Kode Produk wajib diisi"],
    prodUnitRules: [v => !!v || "Unit wajib diisi"],
    prodCategoryRules: [v => !!v || "Categori wajib diisi"],
    options: {
      locale: "pt-BR",
      prefix: "Rp. ",
      suffix: "",
      precision: 0
    }
  }),

  mounted() {
    this.getCategory();
    this.getUnit();
  },

  computed: {
    loadingStatus() {
      return this.$store.getters.getLoading;
    },
    isFile() {
      return this.file;
    }
  },

  methods: {
    getCategory() {
      api()
        .get("category")
        .then(resp => {
          this.categoryData = resp.data.data;
        });
    },
    getUnit() {
      api()
        .get("unit")
        .then(resp => {
          this.unitData = resp.data.data;
        });
    },
    submitData() {
      this.$refs.form.validate();

      let fileName = this.file.name;
      let ext = fileName.split(".").pop();

      if (ext === "xlsx" || ext === "xls") {
        let formData = new FormData();
        let file = this.file;
        formData.append("prodmast_file", file);

        this.$store
          .dispatch("product/uploadStoreProduct", formData)
          .then(() => {
            this.$tostini({
              message: this.$store.state.message,
              type: "success"
            });
            this.file = null;
          })
          .catch(() => {
            this.$tostini({
              message: this.$store.state.message,
              type: "error",
              timeout: 3000
            });
          });
      } else {
        this.$tostini({
          message: ERROR_FILE_FORMAT,
          type: "error"
        });
      }
    },
    submitStoreProduct() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        this.loading = true;
        const params = {
          mitra_product: {
            unit: this.mitraProduct.unit,
            prod_code: this.mitraProduct.prodCode,
            prod_name: this.mitraProduct.prodName,
            category: this.mitraProduct.category,
            cost: this.mitraProduct.cost || 0,
            selling_price: this.mitraProduct.sellingPrice || 0
          }
        };

        api()
          .post("mitra/store-product/store-product-update", params)
          .then(response => {
            if (response.data.status === 0) {
              this.$tostini({
                message: response.data.message,
                type: "error"
              });
            } else {
              this.mitraProduct = {};

              this.$tostini({
                message: "Berhasil Upload",
                type: "success"
              });
            }
          })
          .catch(() => {
            this.$tostini({
              message: "Terjadi Kesalahan",
              type: "error"
            });
          })
          .finally(() => {
            this.loading = false;
            this.$refs.form.reset();
          });
      }
    }
  },
  watch: {
    shown() {
      this.rules = {};
      this.$refs.form.reset();
    }
  }
};
</script>

<style>
#UploadStoreProduct table {
  border-collapse: collapse;
  width: 100%;
}

#UploadStoreProduct table td {
  border: 1px solid #ddd;
  padding: 8px;
}

#UploadStoreProduct table tr:first-child {
  text-align: center;
  font-weight: bold;
  background-color: #002b73;
  color: white;
}

#UploadStoreProduct table td:first-child {
  width: 5%;
  text-align: center;
}
</style>
